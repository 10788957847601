<template>
  <div class="main-content" :style="{ '--themeColor': $store.state.themeInfo.themeColor }">
    <div class="dropdown-menu">
      <div :class="{ 'dropdown-menu-container': true, active: showMenu }">
        <div class="dropdown-ground"></div>
        <div :class="{ menu: true, 'menu-active': showMenu }" @click="handleMenu">
          <div class="menu-line-box">
            <div class="menu-line"></div>
            <div class="menu-line"></div>
          </div>
          <div class="menu-line-box">
            <div class="menu-line"></div>
            <div class="menu-line"></div>
          </div>
          <div class="menu-line-box">
            <div class="menu-line"></div>
            <div class="menu-line"></div>
          </div>
        </div>
        <div
          v-for="(item, index) in menuList"
          :class="{ 'menu-item': true, 'menu-item-active': active === index, hide: !showMenu }"
          :key="item.type"
          @click="changeType(item.type, index)"
          ref="menuItem"
        >
          <img :src="item.icon" alt="">
          {{ item.type }}
        </div>
      </div>
    </div>
    <div class="header">
      <div class="user-name">
        <div class="label-icon">
          <van-icon name="manager" />
        </div>
        {{ content.vipName }}
      </div>
      <div class="user-contact">
        <div class="label-icon">
          <van-icon name="phone" />
        </div>
        会务联系方式：{{ content.workerName }} {{ content.workerPhone }}
      </div>
    </div>
    <div class="travl-body">
      <van-collapse v-model="activeName">
        <van-collapse-item :title="plan.date | dateFilter" v-for="(plan, posi) in contentList" :key="plan.date" :name="posi">
          <div class="travl-container"
            v-for="(item, index) in plan.child"
            :key="index"
          >
            <div :class="{ 'travl-item': true, 'last-item': index === plan.child.length - 1 }">
              <van-tag class="label" type="primary">{{ item.eventType }}</van-tag>
              <div class="time">
                {{ item.startTime }}-{{ item.endTime }}
              </div>
              <div class="content" v-if="item.place"> 
                <van-icon class="icon" name="location-o" />
                {{ item.place }}
              </div>
              <van-divider v-if="item.eventContent"></van-divider>
              <div class="content" v-if="item.eventContent">
                <van-icon class="icon" name="description" />
                {{ item.eventContent }}
              </div>
              <van-divider v-if="item.remarks"></van-divider>
              <div class="content" v-if="item.remarks">
                <van-icon class="icon" name="label-o" />
                {{ item.remarks }}
              </div>
            </div>
          </div>
        </van-collapse-item>
      </van-collapse>
    </div>
    <van-empty description="暂无数据" v-if="list.length === 0"></van-empty>
  </div>
</template>

<script>
export default {
  name: 'vipStroke',
  props: {
    content: {
      type: Object
    }
  },
  data() {
    return {
      list: [],
      contentList: [],
      activeName: [],
      colorList: {
        '用餐': '#ff5000',
        '活动': '#ffbe00',
        '酒店': '#63BCF8',
        '交通': '#43CF43'
      },
      labelList: {
        '用餐': require('@/assets/images/vipjourney/meal.png'),
        '活动': require('@/assets/images/vipjourney/important.png'),
        '酒店': require('@/assets/images/vipjourney/hotel.png'),
        '交通': require('@/assets/images/vipjourney/traffic.png')
      },
      menuList: [
        {
          type: '全部',
          icon: require('@/assets/images/vipjourney/menu-all.png')
        },
        {
          type: '用餐',
          icon: require('@/assets/images/vipjourney/menu-meal.png')
        },
        {
          type: '活动',
          icon: require('@/assets/images/vipjourney/menu-important.png')
        },
        {
          type: '酒店',
          icon: require('@/assets/images/vipjourney/menu-hotel.png')
        },
        {
          type: '交通',
          icon: require('@/assets/images/vipjourney/menu-traffic.png')
        }
      ],
      showMenu: false,
      active: 0
    }
  },
  watch: {
    content: {
      handler(val) {
        if (val.content) {
          this.list = JSON.parse(val.content)
          this.handleList()
        }
      },
      immediate: true
    }
  },
  filters: {
    dateFilter(date) {
      return `${date.split('-')[0]}年${date.split('-')[1]}月${date.split('-')[2]}日`
    }
  },
  methods: {
    changeType(type, index) {
      if (this.active === index) {
        return
      }
      this.active = index
      if (index === 0) {
        this.list = JSON.parse(this.content.content)
      } else {
        this.list = JSON.parse(this.content.content).filter(item => {
          return item.eventType === type
        })
      }
      this.handleList()
    },
    handleMenu() {
      this.showMenu = !this.showMenu
    },
    handleList() {
      this.contentList = []
      let posi = 0
      this.list.forEach((item, index) => {
        if (index === 0 || this.list[index - 1].date !== item.date) {
          this.contentList[posi] = { date: item.date, child: [] }
          this.contentList[posi].child.push(item)
          this.activeName[posi] = posi
          posi ++
        } else {
          this.contentList[posi - 1].child.push(item)
        }
      })
    },
  }
}
</script>

<style scoped lang="less">
@themeColor: var(--themeColor);
.main-content{
  background: #fff;
  height: 100%;
}
.header{
  // height: 2.1rem;
  // background: linear-gradient(to bottom right, #13CBF2 20%, #4CB5F9 80%);
  color: #333;
  padding: 8px 15px;
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, .1);
  .user-name{
    height: 1rem;
    line-height: 1rem;
    font-size: 0.4rem;
    display: flex;
    align-items: center;
  }
  .user-contact{
    display: flex;
    height: 1rem;
    line-height: 1rem;
    font-size: 0.35rem;
    align-items: center;
  }
  .label-icon{
    height: 30PX;
    width: 30PX;
    border-radius: 50%;
    margin-right: 10px;
    font-size: 0.4rem;
    // border: 2px solid @themeColor;
    color: @themeColor;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.travl-container{
  padding: 15px;
  .travl-date{
    font-size: 15px;
    color: #333;
    font-weight: bold;
    margin-bottom: 15px;
  }
  .travl-item{
    position: relative;
    padding: 8px 10px;
    margin-left: 90px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    box-shadow: 0 0 5px 1px rgba(0, 0, 0, .1);
    font-size: 13px;
    border-left: 3px solid @themeColor;
    .label{
      position: absolute;
      background: @themeColor;
      opacity: .5;
      left: -64px;
      top: 15px;
    }
    .time{
      position: absolute;
      top: -4px;
      left: -100px;
      color: #999;
    }
    .content{
      color: #666;
    }
    .icon{
      margin-right: 3px;
      margin-top: 5px;
    }
    &::before{
      content: '';
      position: absolute;
      display: inline-block;
      height: 9PX;
      width: 9PX;
      border-radius: 50%;
      left: -23PX;
      top: 0;
      z-index: 1;
      background: @themeColor;
    }
    &::after{
      content: '';
      position: absolute;
      display: inline-block;
      border-left: 1px dashed #ccc;
      height: calc(100% + 30px);
      left: -19PX;
      top: 0;
      z-index: 0;
      // background: #63BCF8;
    }
  }
  .last-item{
    &::after{
      border-left: none;
    }
  }
}
.van-divider{
  margin: 5px 0;
}
.dropdown-menu{
  position: fixed;
  right: 0;
  top: 55px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  overflow: hidden;
  transition: .3s;
  z-index: 99;
  .dropdown-menu-container{
    position: relative;
    padding: 10px 15px;
    height: 47px;
    transition: .3s;
    .dropdown-ground{
      position: absolute;
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;
      background: @themeColor;
      opacity: .5;
      z-index: -1;
    }
    .menu{
      font-size: 10px;
      margin-bottom: 11px;
      color: #fff;
      height: 30px;
      .menu-line-box{
        position: relative;
        height: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        .menu-line{
          width: 12px;
          height: 2px;
          background: #fff;
          transition: .2s;
          border-radius: 2px;
          &:nth-of-type(1),
          &:nth-of-type(3),
          &:nth-of-type(5){
            transform: rotate(20deg) translateX(1PX);
          }
          &:nth-of-type(2),
          &:nth-of-type(4),
          &:nth-of-type(6){
            transform: rotate(-20deg) translateX(-1PX);
          }
        }
      }
    }
    .menu-active{
      .menu-line-box{
        .menu-line{
          &:nth-of-type(1),
          &:nth-of-type(3),
          &:nth-of-type(5){
            transform: rotate(0) translateX(2PX);
          }
          &:nth-of-type(2),
          &:nth-of-type(4),
          &:nth-of-type(6){
            transform: rotate(0) translateX(-2PX);
          }
        }
      }
    }
    .menu-item{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 10px;
      margin-bottom: 10px;
      height: 40px;
      color: #fff;
      opacity: 1;
      transition: .3s;
      img{
        height: 15px;
        margin-bottom: 10px;
        transition: .2s;
      }
    }
    .menu-item-active{
      img{
        transform: scale(1.8);
      }
    }
    .hide{
      opacity: 0;
    }
  }
  .active{
    height: 300px;
  }
}
/deep/.van-collapse-item__content{
  padding: 15px 0;
}

.scale-enter,
.scale-leave-to{
  opacity: 0;
  transform: scale(0);
}

.dropdown-enter,
.dropdown-leave-to{
  right: -200%;
  opacity: 0;
}
.dropdown-enter-active,
.dropdown-leave-active{
  transition: .3s;
}
</style>
