<template>
  <div :style="{ height: $route.params.isEnter === 'enter' ? 'calc(100vh - 55PX)' : '100vh', '--themeColor': $store.state.themeInfo.themeColor }" class="main">
    <van-sticky>
      <head-bar v-if="$route.params.isEnter === 'enter'" :leftText="'返回'" @onClickLeft="onClickLeft" :leftArrow="true" :title="'vip行程'" />
      <head-bar v-else :leftText="searchSuccess ? '返回' : ''" @onClickLeft="onClickLeft" :leftArrow="searchSuccess" :title="'vip行程'" />
    </van-sticky>
    <template v-if="!searchSuccess">
      <van-form @submit="getArrangement">
        <van-field
          v-if="$store.state.miceInfo.itineraryQueryType !== 3"
          v-model="form.phone"
          required
          label="手机号"
          placeholder="请输入手机号"
          type="digit"
          :error-message="phoneErrorMsg"
          maxlength="11"
          @input="phoneErrorMsg = ''"
          :rules="[{ required: true, message: '请填写正确手机号',pattern : phoneRules, trigger: 'onChange' }]"
        />
        <van-field
          v-if="$store.state.miceInfo.itineraryQueryType === 3"
          required
          v-model="form.name"
          label="姓名"
          placeholder="请输入姓名"
        >
        </van-field>
        <van-field
          required
          label="人员身份类型"
        >
          <template #input>
            <van-radio-group v-model="form.identityType">
              <van-radio class="radio-style" v-for="item in identityTypeList" :key="item.name" :name="item.name">{{ item.label }}</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <van-field
          v-if="$store.state.miceInfo.itineraryQueryType === 1"
          v-model="form.verifyCode"
          center
          required
          type="digit"
          :rules="[{ required: true, message: '请输入验证码', trigger: 'onChange' }]"
          clearable
          label="短信验证码"
          placeholder="请输入短信验证码"
          maxlength="6"
        >
          <template #button>
            <van-button
              size="small"
              :style="{ background: $store.state.themeInfo.themeColor, borderRadius: '5px', border: 'none', color: '#fff' }"
              @click="sendCode"
              :disabled="waitCode"
              native-type="button"
            >
              {{ sendCodeText }}
            </van-button>
          </template>
        </van-field>
        <div class="center-vertical" style="padding: 15px">
          <van-button :disabled="loading" :loading="loading" round block type="info" :style="{ background: $store.state.themeInfo.themeColor, border: 'none' }">查询</van-button>
        </div>
      </van-form>
    </template>
    <div class="trip-body" :style="{ height: $route.params.isEnter === 'enter' ? 'calc(100% - 55PX)' : '100%' }" v-else>
      <transition name="list" appear>
        <div class="user-list" v-if="arrangement.length > 0 && arrangement.length > 1 && !peopleShow">
          <div class="user-item" v-for="(user, index) in arrangement" :key="index" @click="handleShow(user)">
            <van-icon class="label" name="bookmark" />
            <div class="op-back"></div>
            <div class="content">
              <div class="name">{{ user.vipName }}</div>
              <div class="back">
                <van-icon name="down" style="transform:rotate(-90deg)" />
              </div>
            </div>
          </div>
        </div>
      </transition>
      <transition name="trip" appear>
        <journey-list v-if="peopleShow || (arrangement.length > 0 && arrangement.length === 1)" :content="userContent" />
      </transition>
      <van-empty v-if="arrangement.length === 0" description="暂无数据" />
    </div>
  </div>
</template>

<script>
import { Toast } from 'vant'
import headBar from '@/components/headBar/headBar'
import journeyList from './components/journeyList'
export default {
  name: 'myPlan',
  components: { headBar, journeyList },
  data() {
    return {
      arrangement: [],
      refreshLoading: false,
      peopleShow: false,
      interval: null,
      userContent: {},
      form: {
        phone: '',
        verifyCode: '',
        name: '',
        identityType: 1
      },
      identityTypeList: [
        { name: 1, label: '本人' },
        { name: 2, label: '秘书' },
        { name: 3, label: '会务人员' }
      ],
      waitCode: false,
      searchSuccess: false,
      loading: false,
      sendCodeText: '获取验证码',
      phoneErrorMsg: '',
      phoneRules: /^((\+|00)86)?((134\d{4})|((13[0-9]|14[1|5-9]|15[0-9]|16[2|5|6|7]|17[0-8]|18[0-9]|19[0-2|5-9])\d{8}))$/,
    }
  },
  methods: {
    handleShow(user) {
      this.peopleShow = true
      this.userContent = user
    },
    async getArrangement(type) {
      let data = {}
      this.loading = true
      if (this.$store.state.miceInfo.itineraryQueryType === 3) {
        data = {
          name: this.form.name,
          miceLink: this.$store.state.miceInfo.miceLink || this.$route.params.miceLink,
          itineraryQueryType: 3
        }
      } else {
        data = {
          phone: this.form.phone,
          miceLink: this.$store.state.miceInfo.miceLink || this.$route.params.miceLink,
          itineraryQueryType: type === 1 ? 2 : this.$store.state.miceInfo.itineraryQueryType
        }
        if (this.$store.state.miceInfo.itineraryQueryType === 1) {
          data.verifyCode = this.form.verifyCode
        }
      }
      data.identityType = this.form.identityType
      const result = await this.$http.post(this.$baseURL.userArrange.getVipTrip(), data)
      this.refreshLoading = this.loading = false
      if (type === 1) {
        Toast({
          message: '刷新成功',
          position: 'middle'
        })
      } else {
        this.loading = false
        this.searchSuccess = true
      }
      this.arrangement = result
      this.userContent = this.arrangement[0]
    },
    async sendCode() {
      if(this.phoneRules.test(this.form.phone)){
        this.phoneErrorMsg = ''
        this.timeOut()
        await this.$http.post(this.$baseURL.index.sendCode(), {
          phone: this.form.phone
        })
      } else{
        this.phoneErrorMsg = "请填写正确手机号"
      }
    },
    timeOut() { // 验证码倒计时
      let count = 60
      this.waitCode = true
      this.interval = setInterval(() => {
        this.sendCodeText = count + '秒后重试'
        if (count === 0) {
          clearInterval(this.interval)
          this.sendCodeText = '获取验证码'
          this.waitCode = false
        }
        count --
      },1000)
    },
    onClickLeft() {
      if (this.peopleShow) {
        this.peopleShow = false
      } else if (this.searchSuccess) {
        this.searchSuccess = false
      } else if (this.$route.params.isEnter === 'enter') {
        this.$parent.onChange(0)
      }
    },
    goBack() {
      this.searchSuccess = false
      this.arrangement = []
    }
  },
  destroyed() {
    clearTimeout(this.interval)
  }
}
</script>

<style lang="less" scoped>
@themeColor: var(--themeColor);
.main{
  height: 100vh;
  background: #f5f5fa;
  /deep/ .van-pull-refresh{
    overflow: visible;
  }
}
.trip-body{
  background: #fff;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  .user-list{
    padding: 15px;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    .user-item{
      position: relative;
      margin-bottom: 15px;
      padding: 8px;
      height: 90px;
      box-shadow: 0 1px 3px 0 rgba(0,0,23,.08);
      border-radius: 5px;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      .label{
        position: absolute;
        left: 25px;
        top: 2px;
        z-index: 2;
        color: @themeColor
      }
      .op-back{
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        background: @themeColor;
        opacity: .1;
        z-index: 0;
      }
      .content{
        position: absolute;
        height: calc(100% - 16px);
        width: calc(100% - 16px);
        background: #fff;
        box-shadow: 0 1px 3px 0 rgba(0,0,23,.08);
        z-index: 1;
        display: flex;
        border-radius: 5px;
        color: @themeColor;
        align-items: center;
        .name{
          font-size: 17px;
          padding-left: 40px;
          flex: 4;
        }
        .back{
          margin-right: 30px;
          font-size: 15px;
          height: 35PX;
          width: 35PX;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 3px solid @themeColor;
          border-radius: 50%;
        }
      }
    }
  }
}
/deep/.van-field{
  &::before{
    content: ''
  }
}
/deep/.van-field__label{
  color: #222;
  font-size: 13px;
  font-weight: bold;
  margin-right: 0.8rem;
}
/deep/.van-field__control{
  &::-webkit-input-placeholder{
    color: #999;
    font-size: 13px;
  }
}
/deep/.van-radio__icon--checked .van-icon{
  background-color: @themeColor;
  border-color: @themeColor;
}
.radio-style{
  margin-bottom: 10px;
}
.list-enter-to,
.list-leave-to,
.trip-enter-to,
.trip-leave-to{
  transition: all .3s ease;
}
.list-enter,
.list-leave-to{
  opacity: 0;
  transform: translateX(-100%);
}
.trip-enter,
.trip-leave-to{
  opacity: 0;
  transform: translateX(100%);
}
</style>